import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../components/common/CustomSelect";
import ConfirmModal from "../../../components/common/ConfirmModal";
import defaultUser from "../../../assets/images/user/default_user-gray.png";
import { prodApiUrl, stageApiUrl } from "../../../utilities/constants";

const AddNewNews = ({ details, setDetails, list, changesModal, setChangesModal, setShowAddNew }) => {

  const postingEvery = ["M", "T", "W", "TH", "F", "S", "SU"];
  const subCommitteeOptions = [{ value: "EVENT" }, { value: "ARC" }];
  const postToOptions = [{ value: "ALL" }, { value: "HOMEOWNER" }, { value: "BOARD" }, { value: "PROPERTY" }];
  const fileInputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [pictureLoading, setPictureLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [loadDelete, setLoadDelete] = useState(false);
  const [form, setForm] = useState({
    documents: [{ name: null, file: null }, { name: null, file: null }, { name: null, file: null }],
    subCommittee: "",
    title: "",
    description: "",
    posting: "DAILY",
    postingDay: [],
    postingEmailId: "",
    picture: null,
    postTo: "",
  })

  const handleFileChange = async (e, index) => {
    setFileLoading(true);
    const formData = new FormData();
    const file = e.target.files[0]
    formData.append('file', file);

    try {
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/news/upload/document', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({
        ...form,
        documents: form.documents.map((item, i) => {
          if (i === index) return { type: "OTHER", name: file.name, file: uploadedPicture?.directoryPath }
          else return item
        })
      })
      setFileLoading(false);
      const inputfile = document.getElementsByName(`documents[${index}]`)?.[0];
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.message);
      setFileLoading(false);
    }
  }

  const handleFormChange = (e, name, id) => {

    if (!changesModal.fields.find(f => f === name)) setChangesModal({ ...changesModal, fields: [...changesModal.fields, name] })

    if (name === "posting_daily") {
      setForm({
        ...form,
        posting: "DAILY",
        postingDay: [],
      })
    }
    else if (name === "posting_every") {
      setForm({
        ...form,
        posting: "EVERY"
      })
    }
    else if (name === "posting_day") {
      if (e.target.checked) {
        setForm({ ...form, postingDay: [...form.postingDay, id] })
      } else {
        setForm({ ...form, postingDay: form.postingDay.filter(item => item !== id) })
      }
    }
    else if (name === "subCommittee" || name === "postingEmailId" || name === "postTo") {
      setForm({ ...form, [name]: e })
    }
    else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const handleAttachPicture = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      setPictureLoading(true);
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/news/upload/document', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({ ...form, picture: { type: "MAIN", name: e.target.files[0].name, file: uploadedPicture?.directoryPath } });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      const inputfile = document.getElementsByName("picture")?.[0];
      inputfile.value = "";
      setPictureLoading(false);
    }
  }

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/voting/delete/${details?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    if (form.posting === "EVERY" && form.postingDay.length === 0) {
      toast.error("Select at least 1 posting day.");
      return;
    }

    if (form.picture === null) {
      toast.error("Main picture is required.");
      return;
    }

    try {
      setLoading(true);
      const params = {
        title: form.title,
        description: form.description,
        subCommittee: form.subCommittee.value,
        posting: form.posting,
        postingDay: form.postingDay,
        emailTemplateId: form.postingEmailId.id,
        postTo: form.postTo.value,
        documents: [form.picture, ...form.documents.filter(doc => doc.name)]
      };

      // if (type === "add") {
        const { data } = await useGetApiClient.post('/admin/news/save',
          params
        );

        if (data?.error) throw new Error(data?.error);
        setOpenModal({ ...openModal, save: true })

      // } else {
      //   const { data } = await useGetApiClient.put(`admin/configuration/voting/update/${details?.id}`,
      //     params
      //   );
      //   if (data?.error) throw new Error(data?.error);
      //   setOpenModal({ ...openModal, save: true })

      // }
      setLoading(false);
      setChangesModal({ open: false, fields: [], targetTab: null })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (details) {
      setForm({
        ...form,
        name: details?.name,
        description: details?.description,
        picture: details.picture?.includes(stageApiUrl)
          ? details.picture?.replaceAll(stageApiUrl, "")
          : details.picture?.includes(prodApiUrl)
            ? details.picture?.replaceAll(prodApiUrl, "")
            : defaultUser,
        posting: details.posting,
        postingDay: details.postingDay,
        postingEmailId: details.postingEmail,
      })
    }
  }, [details, list])

  return (
    <>
      <Form
        onSubmit={(event) => {
          handleSubmit({
            event,
            type: details ? "update" : "add"
          })
        }}
      >
        <Card className="config-tasks-list">
          <div className="config-tasks-list_header">
            <div className="d-flex flex-row justify-content-between w-100">
              <h5 className="text-primary">News</h5>
              {details?.id &&
                <Button
                  className="btn btn-sm btn-danger-subtle d-flex align-items-center rounded-3"
                  style={{ border: "unset" }}
                  onClick={loadDelete ? () => { } : () => setOpenModal({ ...openModal, confirmDelete: true })}
                >
                  {loadDelete
                    ? <SpinningDynamicCircleLoader color="var(--bs-danger)" />
                    : <i className="material-symbols-outlined md-18">delete</i>
                  }
                </Button>
              }
            </div>
          </div>
          <Card.Body className="pt-0">
            <Row className="form-group align-self-center">
              <div className="col-sm-7 d-flex flex-column justify-content-center">
                {!pictureLoading ? (
                  form.picture === null
                    ? <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "lightgray", width: "100%", height: "450px" }}>
                      <i className="material-symbols-outlined">photo_camera</i>
                    </div>
                    : <img
                      src={form.picture.file}
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                ) : <SpinningBlueCircleLoader />}
                <div className="d-flex flex-row gap-3 mt-4" style={{ marginLeft: "auto", marginRight: "auto" }}>
                  <div className="d-flex align-items-center">
                    <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <i className="material-symbols-outlined">attach_file</i>
                    </label>
                    <input
                      id="picture"
                      name="picture"
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={(e) => handleAttachPicture(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="border-0 bg-transparent d-flex align-items-center text-danger"
                    onClick={() => setForm({ ...form, picture: null })}
                  >
                    <i className="material-symbols-outlined">delete</i>
                  </button>
                </div>
              </div>
              <div className="col-sm-5">
                {form.documents.map((item, index) => (
                  <Row className="form-group align-items-center" key={index}>
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, documents: [...form.documents, { name: null, file: null }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-9" style={{ height: 41 }}>
                      <div className="form-control" style={{ minHeight: "41px" }}>
                        {item.name &&
                          <div className="d-flex flex-row align-items-center justify-content-between gap-1 rounded-3 bg-danger" style={{ width: "fit-content", color: "white", padding: "5px 8px" }}>
                            <span>{item.name}</span>
                            {/* {form.documents.length > 1 && */}
                            <i className="material-symbols-outlined w-auto cursor-pointer" onClick={() => setForm({
                              ...form, documents: form.documents.map((item, i) => {
                                if (i === index) return { name: null, file: null }
                                else return item
                              })
                            })}>close</i>
                            {/* } */}
                          </div>
                        }
                      </div>
                      <input
                        type="file"
                        disabled={fileLoading}
                        ref={(el) => (fileInputRefs.current[index] = el)}
                        style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0 }}
                        name={`documents[${index}]`}
                        onChange={(e) => handleFileChange(e, index)}
                      />
                    </div>
                    {fileLoading ?
                      <div style={{ width: 42 }}>
                        <SpinningBlueCircleLoader />
                      </div> :
                      <i className="material-symbols-outlined text-primary w-auto cursor-pointer" onClick={() => fileInputRefs.current[index].click()}>upload</i>
                    }
                  </Row>
                ))}

                <h6 className="text-primary mb-3 mt-5">Sub-committee</h6>
                <CustomSelect
                  value={form.subCommittee}
                  onChange={(e) => handleFormChange(e, "subCommittee")}
                  options={subCommitteeOptions || []}
                  optLabelKey="value"
                  optValueKey="value"
                  placeholder="-"
                  required
                />

                <h6 className="text-primary mb-3 mt-5">Posting</h6>
                <div className="col-sm-12">
                  <Form.Check className="d-block mb-0">
                    <Form.Check.Input
                      type="radio"
                      name="posting_daily"
                      id="posting_daily"
                      checked={form.posting === "DAILY"}
                      onChange={(e) => handleFormChange(e, "posting_daily")}
                    />{" "}
                    <Form.Check.Label>
                      Repost daily
                    </Form.Check.Label>
                  </Form.Check>
                </div>
                <div className="col-sm-12 mt-2">
                  <Form.Check className="d-block mb-0">
                    <Form.Check.Input
                      type="radio"
                      name="posting_every"
                      id="posting_every"
                      checked={form.posting === "EVERY"}
                      onChange={(e) => handleFormChange(e, "posting_every")}
                    />{" "}
                    <Form.Check.Label>
                      Repost every
                    </Form.Check.Label>
                  </Form.Check>
                </div>
                <div className="col-sm-12 d-flex align-items-center gap-4 mt-2 mb-2">
                  {postingEvery.map((item, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={item}
                      name={`postingDay_${item}`}
                      checked={form.postingDay.includes(item)}
                      onChange={(e) => handleFormChange(e, "posting_day", item)}
                      disabled={form.posting === "DAILY"}
                    />
                  ))}
                </div>

                <CustomSelect
                  value={form.postingEmailId}
                  onChange={(e) => handleFormChange(e, "postingEmailId")}
                  options={list?.emailTemplates || []}
                  optLabelKey="notificationId"
                  optValueKey="id"
                  placeholder="Email template"
                  required
                />
              </div>

              <h6 className="text-primary mb-3 mt-3">Your News</h6>
              <label className="control-label mb-2" htmlFor="form.title">Title</label>
              <input type="text" value={form.title} className="form-control" id="title" name="title" placeholder="-" onChange={(e) => handleFormChange(e, "title")} required />

              <label className="control-label mb-2 mt-3" htmlFor="form.emailTemplateId">Send to</label>
              <CustomSelect
                value={form.postTo}
                onChange={(e) => handleFormChange(e, "postTo")}
                options={postToOptions || []}
                optLabelKey="value"
                optValueKey="value"
                placeholder="-"
                required
              />

              <label className="control-label mb-2 mt-3" htmlFor="form.description">Description</label>
              <textarea
                type="text"
                value={form?.description ?? ""}
                onChange={(e) => handleFormChange(e, "description")}
                className="form-control"
                id="description"
                name="description"
                placeholder="-"
                style={{ minHeight: "100px" }}
              />
            </Row>
          </Card.Body>
        </Card>

        <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
          <button type="submit" className="btn btn-primary" style={{ width: "30%" }}>
            {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
          </button>
        </Form.Check>

      </Form >

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNew(false);
            setDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" disabled={loadDelete} onClick={handleDelete}>
            {loadDelete ? <SpinningWhiteCircleLoader /> : "Yes"}
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />
    </>
  );
}

export default AddNewNews;