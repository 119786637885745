import { useEffect, useState } from "react";
import { Button, Card, Row } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import Pagination from "../../../components/common/pagination";
import AddNewNews from "./addNewNews";
import NewsIcon from "../../../assets/images/icon/news.svg";

const NewsList = ({ showAddNew, setShowAddNew, details, setDetails, changesModal, setChangesModal }) => {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [loadDetail, setLoadDetail] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [selectedNews, setSelectedNews] = useState(null);

  const handleClickItem = async (item) => {
    setLoadDetail(true)
    try {
      setSelectedNews(item.id);
      const { data } = await useGetApiClient.get(`/admin/news/get/${item.id}`);
      if (data?.error) throw new Error(data?.error);
      setDetails(data);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDetail(false);
    }
  }

  useEffect(() => {
    const getlist = async () => {
      setLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/news/list?perPage=${perPage}&currentPage=${page}`);
        if (data?.error) throw new Error(data?.error);
        setList(data || [])
        data?.news?.length > 0 && handleClickItem(data?.news?.[0]);
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }

    getlist();
  }, [showAddNew, page, perPage]);

  return (
    <>
      {!showAddNew
        ? <>
          <Card className="config-tasks-list mt-3">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div className="d-flex flex-row gap-4">
                  <h5
                    className="text-primary"
                  // onClick={() => setArchived(false)}
                  >
                    News
                  </h5>
                  {/* <h5
                    className={`${archived ? 'text-decoration-underline fw-bold' : ''} text-primary ms-2`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setArchived(true)}
                  >
                    Archive
                  </h5> */}
                </div>
                <Button
                  className="btn btn-sm btn-success d-flex align-items-center rounded-3"
                  disabled={loading}
                  onClick={() => setShowAddNew(true)}
                >
                  <i className="material-symbols-outlined me-1 md-18">add</i>
                  Create News
                </Button>
              </div>
            </Card.Header>
          </Card>
          <div className="d-flex flex-row gap-4">
            <div style={{ width: "350px", paddingBottom: "1.5rem" }}>
              <div style={{maxHeight: 500, overflowY: "auto" }}>
              {(loading)
                ? <Card style={{ padding: 20 }}><SpinningBlueCircleLoader /></Card>
                : (list?.news?.length > 0)
                  ? list?.news?.map((item, index) => (
                    // <Card key={index} onClick={() => handleClickArc(item)} style={{ cursor: "pointer" }}>
                    <Card key={index} style={{ cursor: "pointer", backgroundColor: selectedNews === item.id ? "var(--bs-primary-bg-subtle)" : "white" }} onClick={() => handleClickItem(item)}>
                      <div className="d-flex flex-row align-items-center gap-3 p-4">
                        <img src={NewsIcon} style={{ filter: index % 3 === 0 ? "unset" : index % 3 === 1 ? "brightness(0) saturate(100%) invert(65%) sepia(39%) saturate(1152%) hue-rotate(214deg) brightness(99%) contrast(104%)" : "brightness(0) saturate(100%) invert(79%) sepia(14%) saturate(1790%) hue-rotate(334deg) brightness(103%) contrast(100%)" }} />
                        <div className="me-1" style={{ color: index % 3 === 0 ? "#F7685B" : index % 3 === 1 ? "#D592FF" : "#FFBA68" }}>{item.title}</div>
                      </div>
                    </Card>
                  ))
                  : <Card style={{ padding: 20 }}>No record found</Card>
              }
              </div>
              <Pagination page={page} setPage={setPage} perPage={perPage} setPerPage={setPerPage} showRowsPerPage={false} totalCount={list?.count ?? 0} />
            </div>
            <Card className="config-tasks-list" style={{ width: "100%" }}>
              <Card.Body>
                {(loading || loadDetail)
                  ? <SpinningBlueCircleLoader />
                  : <div className="col-sm-7 d-flex flex-column justify-content-center w-100">
                    <img
                      src={details?.documets?.find(doc => doc.type === "MAIN")?.file}
                      className="img-fluid"
                      alt="user"
                      loading="lazy"
                    />
                  </div>
                }
              </Card.Body>
            </Card>
          </div>
          <Card className="config-tasks-list" style={{ width: "100%" }}>
            <Card.Body>
              <h5 className="text-primary mb-3">Latest News</h5>
              {(loading || loadDetail)
                ? <SpinningBlueCircleLoader />
                : <>{details?.description}
                </>
              }
            </Card.Body>
          </Card>
        </>
        : <AddNewNews list={list} changesModal={changesModal} setChangesModal={setChangesModal} setShowAddNew={setShowAddNew} />
      }
    </>
  )
}

export default NewsList;