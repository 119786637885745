import { useState } from "react";
import { Card } from "react-bootstrap";
import { SpinningBlueCircleLoader } from "../../components/common/loaders";
import * as moment from "moment";

const BillingStatus = () => {

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();


  const columnHeader = ["Account", "House", "Owner Name", "Date Sent", "Date Billed", "Description", "Status"];

  const rows = (row) => {
    return (
      <>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.referenceNumber}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.name}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.requestedBy}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.createdAt}</div></td>
        <td><div className="d-flex align-items-center" style={{ height: '50px' }}>{row.endDate}</div></td>
        <td>
          <div className="d-flex align-items-center" style={{ height: '50px' }}>
            <div className={`${row.status?.toLowerCase() === "disapproves" ? 'bg-danger' : row.status?.toLowerCase() === "in-progress" ? 'bg-primary' : 'bg-success'} py-1 px-2 rounded-3 text-white`} style={{ fontSize: '12px', width: "fit-content" }}>{row.status}</div>
          </div>
        </td>
      </>
    )
  }

  return (
    <Card className="config-tasks-list mt-3">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="d-flex flex-row gap-4">
            <h5
              className="text-primary"
            >
              Billing
            </h5>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, color: "white" }}>Statements - {moment().format('MMMM')}</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeader.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                : (list?.arcRequests?.length > 0)
                  ? list?.arcRequests?.map((item, idx) => (
                    <tr key={idx}>
                      {rows(item)}
                    </tr>
                  ))
                  : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
        </div>

        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, marginTop: 20, color: "white" }}>Last Month Statements</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeader.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                : (list?.arcRequests?.length > 0)
                  ? list?.arcRequests?.map((item, idx) => (
                    <tr key={idx}>
                      {rows(item)}
                    </tr>
                  ))
                  : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
        </div>

        <div style={{ backgroundColor: "#56bcfd", borderRadius: "5px 5px 0px 0px", padding: "10px", marginBottom: 10, marginTop: 20, color: "white" }}>Unpaid</div>
        <div className="table-responsive">
          <table className="config-tasks-list_table table">
            <thead>
              <tr>
                {columnHeader.map((item, index) => (
                  <th key={index} scope="col" className="config-tasks-list_table--header text-primary">{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? <tr><td colSpan={columnHeader.length}><SpinningBlueCircleLoader /></td></tr>
                : (list?.arcRequests?.length > 0)
                  ? list?.arcRequests?.map((item, idx) => (
                    <tr key={idx}>
                      {rows(item)}
                    </tr>
                  ))
                  : <tr><td colSpan={columnHeader.length} className="text-center">No record found</td></tr>
              }
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
}

export default BillingStatus;