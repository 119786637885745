import { useRef, useState } from "react";
import { useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { SpinningWhiteCircleLoader } from "../../../components/common/loaders";

const { Card, Form, Accordion, Row } = require("react-bootstrap");

const BillingList = () => {
  const fileInputRef = useRef();
  const [toggleView, setToggleView] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [form, setForm] = useState({
    monthlyFeeDesc: "",
    monthlyFeeAmount: "",
    rentalFeeDesc: "",
    rentalFeeAmount: "",
    autoGenerate: "",
    generateDay: "",
    generateTime: "",
    invoiceDay: "",
    dueDate: "",
    penalty: "",
    filePath: "",
    account: "",
    secret: "",
    getEvery: "",
    getEveryTime: "",
    file: {
      name: "",
      file: "",
      original_file: "",
      fileType: ""
    },
    amenities: [{ description: "", rate: "" }],
    services: [{ description: "", rate: "" }],
    columns: [{ name: "", column: "" }],
  });

  const handleSelectView = (file) => {
    const params = {
      ...file,
      fileType: file?.fileType,
      path: file.file.toLowerCase().includes("https://")
        ? file.file
        : file.original_file,
    };
    setFileView(params);
    setToggleView(true);
  };

  const handleFileChange = async (e, index) => {
    setFileLoading(true);
    const reader = new FileReader();
    const formData = new FormData();
    const file = e.target.files[0]
    formData.append('file', file);
    reader.readAsDataURL(file);
    let original_file = null;

    reader.onload = (e) => {
      original_file = e.target.result;
    };

    try {
      const { data } = await useUploadPictureApi.post('/admin/document/upload', formData);
      if (data?.error) throw new Error(data?.error);
      setForm({
        ...form,
        file: {
          name: file.name,
          file: data?.directoryPath,
          original_file: original_file,
          fileType: data?.directoryPath?.slice(data?.directoryPath.lastIndexOf(".") + 1, data?.directoryPath.length)
        }
      });
      setFileLoading(false);
      const inputfile = document.getElementsByName(`upload_billing`);
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.message);
      setFileLoading(false);
    }
  }

  const handleFormChange = (e, name, id) => {
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="config-tasks-list">
        <div className="config-tasks-list_header">
          <div className="d-flex flex-row justify-content-between w-100">
            <h5 className="text-primary">
              Billing
            </h5>
          </div>
        </div>

        <Card.Body className="pt-0">
          <Accordion id="adminMenu-0" defaultActiveKey={0}>
            <Accordion.Item key={0} className="mb-3 bg-primary" eventKey={0}>
              <Accordion.Header id={0}>
                Main Billing
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Monthly Fee</label>
                  <div className="col-sm-5">
                    <input type="text" value={form.monthlyFeeDesc} className="form-control" id="name" name="name" placeholder="Description" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                  <div className="col-sm-5">
                    <input type="number" value={form.monthlyFeeAmount} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Rental Fee</label>
                  <div className="col-sm-5">
                    <input type="text" value={form.rentalFeeDesc} className="form-control" id="name" name="name" placeholder="Description" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                  <div className="col-sm-5">
                    <input type="number" value={form.rentalFeeAmount} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-1" defaultActiveKey={1}>
            <Accordion.Item key={1} className="mb-3 bg-primary" eventKey={1}>
              <Accordion.Header id={1}>
                Auto Generate Billing
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Generate billing every</label>
                  <div className="col-sm-3">
                    <input type="number" value={form.generateDay} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                  <label className="control-label align-self-center mb-2" style={{ width: "fit-content" }} htmlFor="name">of the month</label>
                  <div className="col-sm-3">
                    <input type="time" value={form.generateTime} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Show invoice date</label>
                  <div className="col-sm-3">
                    <input type="number" value={form.generateDay} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Due date</label>
                  <div className="col-sm-3">
                    <input type="number" value={form.generateDay} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Penalty after due</label>
                  <div className="col-sm-3">
                    <input type="number" value={form.generateDay} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-2" defaultActiveKey={2}>
            <Accordion.Item key={2} className="mb-3 bg-primary" eventKey={2}>
              <Accordion.Header id={1}>
                Amenities Rates
              </Accordion.Header>
              <Accordion.Body>
                {form.amenities.map((item, index) => (
                  <Row key={index} className="form-group align-items-end">
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, amenities: [...form.amenities, { description: "", rate: "" }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.amenities[${index}].description`}>Amenities</label>
                      <input type="text" value={form.amenities[index].description} className="form-control" id={`form.amenities[${index}].description`} name={`form.amenities[${index}].description`} placeholder="-" onChange={(e) => handleFormChange(e, "amenities", index)} required />
                    </div>
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.amenities[${index}].rate`}>Rate</label>
                      <input type="number" value={form.amenities[index].rate} className="form-control" id={`form.amenities[${index}].rate`} name={`form.amenities[${index}].rate`} placeholder="-" onChange={(e) => handleFormChange(e, "amenities", index)} required />
                    </div>
                    {form.amenities.length > 1 &&
                      <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, amenities: form.amenities.filter((_, i) => i !== index) })}>delete</i>
                    }
                  </Row>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-3" defaultActiveKey={3}>
            <Accordion.Item key={3} className="mb-3 bg-primary" eventKey={3}>
              <Accordion.Header id={1}>
                Other Services Rate
              </Accordion.Header>
              <Accordion.Body>
                {form.services.map((item, index) => (
                  <Row key={index} className="form-group align-items-end">
                    {index === 0
                      ? <i className="material-symbols-outlined filled text-primary w-auto mb-2 cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, services: [...form.services, { description: "", rate: "" }] })}>add_box</i>
                      : <div style={{ width: "41px" }} />
                    }
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.services[${index}].description`}>Service Name</label>
                      <input type="text" value={form.services[index].description} className="form-control" id={`form.services[${index}].description`} name={`form.services[${index}].description`} placeholder="-" onChange={(e) => handleFormChange(e, "services", index)} required />
                    </div>
                    <div className="col-sm-8 col-md-5">
                      <label className="control-label mb-2" htmlFor={`form.services[${index}].rate`}>Rate</label>
                      <input type="number" value={form.services[index].rate} className="form-control" id={`form.services[${index}].rate`} name={`form.services[${index}].rate`} placeholder="-" onChange={(e) => handleFormChange(e, "services", index)} required />
                    </div>
                    {form.services.length > 1 &&
                      <i className="material-symbols-outlined text-danger w-auto mb-2 cursor-pointer" onClick={() => setForm({ ...form, services: form.services.filter((_, i) => i !== index) })}>delete</i>
                    }
                  </Row>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-4" defaultActiveKey={4}>
            <Accordion.Item key={4} className="mb-3 bg-primary" eventKey={4}>
              <Accordion.Header id={1}>
                Stripe Settings
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Endpoint</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.endpoint} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Account</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.account} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Secret</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.secret} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-5" defaultActiveKey={5}>
            <Accordion.Item key={5} className="mb-3 bg-primary" eventKey={5}>
              <Accordion.Header id={1}>
                Billing Upload
              </Accordion.Header>
              <Accordion.Body>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Upload Acclaimed</label>
                  <div className="col-sm-3">
                    <input type="text" value={form.filePath} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "name")} required />
                  </div>
                </Row>
                <Row className="form-group align-items-center">
                  <label className="control-label col-sm-2 align-self-center mb-2" htmlFor="name">Get file every</label>
                  <div className="col-sm-3">
                    <input type="number" value={form.getEvery} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "getEvery")} required />
                  </div>
                  <label className="control-label align-self-center mb-2" style={{ width: "fit-content" }} htmlFor="name">day of the month, at</label>
                  <div className="col-sm-3">
                    <input type="time" value={form.getEveryTime} className="form-control" id="name" name="name" placeholder="-" onChange={(e) => handleFormChange(e, "getEveryTime")} required />
                  </div>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion id="adminMenu-5" defaultActiveKey={5}>
            <Accordion.Item key={5} className="mb-3 bg-primary" eventKey={5}>
              <Accordion.Header id={1}>
                System File Columns
              </Accordion.Header>
              <Accordion.Body>
                <label className="control-label col-sm-12 align-self-center mb-2" htmlFor="name">Upload Billing (CSV)</label>
                <Row className="form-group align-items-start">
                  <div className="col-sm-6 col-lg-5" style={{ height: 70 }}>
                    <div className="form-control" style={{ minHeight: "50px" }}>
                      {form.file.name &&
                        <div className="d-flex flex-row align-items-center justify-content-between gap-1 rounded-3 bg-danger" style={{ width: "fit-content", color: "white", padding: "5px 8px", cursor: "pointer" }} onClick={() => handleSelectView(form.file)}>
                          <span>{form.file.name}</span>
                        </div>
                      }
                    </div>
                    <input
                      type="file"
                      disabled={fileLoading}
                      ref={fileInputRef}
                      style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0 }}
                      name="upload_billing"
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>
                  <i className="material-symbols-outlined text-primary w-auto cursor-pointer" style={{ marginTop: 10 }} onClick={() => fileInputRef.current.click()}>upload</i>
                </Row>
                <button type="submit" className="btn btn-primary" style={{ width: "30%" }}>
                  {!loading ? 'Post' : <SpinningWhiteCircleLoader />}
                </button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>

      <Form.Check className="form-group d-flex align-items-center justify-content-center gap-2">
        <button type="submit" className="btn btn-primary w-50">
          {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
        </button>
      </Form.Check>
    </Form>

  );
}

export default BillingList;