import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SpinningBlueCircleLoader, SpinningDynamicCircleLoader, SpinningWhiteCircleLoader } from "../../../components/common/loaders";
import { useGetApiClient, useUploadPictureApi } from "../../../api/useApiClient";
import { toast } from "react-toastify";
import { isValidJson } from "../../../utilities/helpers";
import ConfirmModal from "../../../components/common/ConfirmModal";
import { CustomSelect } from "../../../components/common/CustomSelect";
import defaultImage from "../../../assets/images/user/default_image.png";
import FileViewerComp from "../../../components/common/FileViewerComp";

const ViolationStatus = ({ setShowAddNew, details, setDetails }) => {

  const isView = details?.status !== "IN-PROGRESS";
  const fileInputRefs = useRef([]);

  const [loading, setLoading] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [message, setMessage] = useState(EditorState.createEmpty());
  const [openModal, setOpenModal] = useState({ save: false, delete: false, confirmDelete: false });
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [imageLoading, setImageLoading] = useState("");
  const [status, setStatus] = useState({ value: details?.status || "" });
  const [refetchComment, setRefetchComment] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [form, setForm] = useState({
    comment: "",
    documents: [{ name: "", file: defaultImage }],
  })

  const handleFormChange = (e, name) => {

    if (name === "type") {
      setForm({ ...form, [name]: e })
    }

    else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const handleSelectView = (file) => {
    const params = {
      ...file,
      path: file.file.toLowerCase().includes("https://")
        ? file.file
        : `https://${file.file}`,
    };
    setFileView(params);
    setToggleView(true);
  };

  const handleEditorChange = (state) => {
    setMessage(state);
    const contentState = state.getCurrentContent();
    const plainText = contentState.getPlainText().trim();
    const hiddenInput = document.getElementById('message');
    hiddenInput.value = plainText;
  };

  const handleAttachPicture = async (e, index) => {

    setImageLoading(index);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      const { data: uploadedPicture } = await useUploadPictureApi.post('/admin/request/violation/upload/document', formData);
      if (uploadedPicture?.error) throw new Error(uploadedPicture?.error);
      setForm({
        ...form, documents: form.documents.map((item, i) => {
          if (i === index) {
            return { name: e.target.files[0]?.name, file: uploadedPicture?.directoryPath }
            // return { name: e.target.files[0]?.name, file: defaultImage }
          } else return item
        })
      })
      setImageLoading("");
      const inputfile = document.getElementsByName(`picture[${index}]`)?.[0];
      inputfile.value = "";
    } catch (error) {
      toast.error(error?.message);
      setImageLoading("");
    }
  }

  const handleDelete = async () => {
    setLoadDelete(true)
    try {
      const { data } = await useGetApiClient.delete(`/admin/configuration/notification/delete/${details?.id}`);
      if (data?.error) throw new Error(data?.error);
      // toast.success(data?.message);
      setOpenModal({ ...openModal, confirmDelete: false, delete: true })
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoadDelete(false);
    }
  }

  const handleChangeStatus = async (e) => {
    try {
      const params = {
        status: e.value
      }
      const { data } = await useGetApiClient.put(`/admin/request/violation/change/status/${details?.id}`,
        params
      );

      if (data?.error) throw new Error(data?.error);
      // toast.success(savedNotification?.message);
      setOpenModal({ ...openModal, save: true })
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
    }
  }

  const handleSubmit = async ({ event, type }) => {
    event.preventDefault();

    try {
      setLoading(true);
      const params = {
        violationReportId: details?.id,
        comment: form.comment,
        documents: form.documents
      }
      const { data } = await useGetApiClient.post('/admin/request/violation/comment/save',
        params
      );

      if (data?.error) throw new Error(data?.error);
      // toast.success(savedNotification?.message);
      setOpenModal({ ...openModal, save: true })
      setLoading(false);
      setRefetchComment(!refetchComment);
      setForm({ comment: "", documents: [{ name: "", file: defaultImage }] });
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message);
      setLoading(false);
    }

    // try {
    //   setLoading(true);
    //   const params = {
    //     notificationId: event.target.notifId.value,
    //     description: event.target.description.value,
    //     from: event.target.from.value,
    //     subject: event.target.subject.value,
    //     body: JSON.stringify(
    //       convertToRaw(message.getCurrentContent())
    //     ),
    //   };

    //   if (type === "add") {
    //     const { data: savedNotification } = await useGetApiClient.post('/admin/configuration/notification/save',
    //       params
    //     );

    //     if (savedNotification?.error) throw new Error(savedNotification?.error);
    //     // toast.success(savedNotification?.message);
    //     setOpenModal({ ...openModal, save: true })

    //   } else {
    //     const { data: updatedNotification } = await useGetApiClient.put(`admin/configuration/notification/update/${details?.id}`,
    //       params
    //     );
    //     if (updatedNotification?.error) throw new Error(updatedNotification?.error);
    //     // toast.success(updatedNotification?.message);
    //     setOpenModal({ ...openModal, save: true })
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   toast.error(error?.response?.data?.message ?? error?.message);
    //   setLoading(false);
    // }
  }

  useEffect(() => {
    const getComments = async () => {
      setCommentsLoading(true);
      try {
        const { data } = await useGetApiClient.get(`/admin/request/violation/comment/get/${details?.id}`);
        if (data?.error) throw new Error(data?.error);
        setComments(data?.comments ?? [])
        setDocuments(data?.comments?.flatMap(item => item.documents));
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setCommentsLoading(false);
      }
    }

    details?.id && getComments();
  }, [refetchComment]);

  return (
    <>
      <Card className="config-tasks-list">
        <div className="config-tasks-list_header">
          <h5 className="text-primary">Violation Status</h5>
        </div>

        <Card.Body className="pt-0">
          <Form
            onSubmit={(event) => handleSubmit({
              event,
              type: details ? "update" : "add"
            })}
          >
            <h6 className="text-primary">Reference Number: {details?.referenceNumber ?? ""}</h6>
            <Row className="form-group">
              <div className="col-sm-7">
                <label className="control-label align-self-center mt-5" htmlFor="description">Description</label>
                <textarea
                  type="text"
                  value={details?.description ?? ""}
                  className="form-control"
                  id="description"
                  name="description"
                  placeholder="-"
                  readOnly
                  style={{ minHeight: "100px" }}
                />
                <label className="control-label align-self-center mt-5" htmlFor="materials">Materials</label>
                <textarea
                  type="text"
                  value={details?.materials ?? ""}
                  className="form-control"
                  id="materials"
                  name="materials"
                  placeholder="-"
                  readOnly
                  style={{ minHeight: "100px" }}
                />
                {/* <div className="form-control">
                  <Editor
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "embedded",
                        "emoji",
                        "remove",
                        "history",
                      ],
                    }}
                    editorState={message}
                    onEditorStateChange={handleEditorChange}
                    editorStyle={{
                      minHeight: "100px",
                      overflow: "auto",
                    }}
                  />
                  <input
                    type="text"
                    id="message"
                    name="message"
                    style={{ opacity: 0, pointerEvents: 'none', height: 0, width: 0, }}
                    required
                  />
                </div> */}
                {!isView &&
                  <>
                    <label className="control-label align-self-center mt-5" htmlFor="comment">Message Body</label>
                    <textarea
                      type="text"
                      value={form.comment}
                      className="form-control"
                      id="comment"
                      name="comment"
                      placeholder="-"
                      onChange={(e) => handleFormChange(e, "comment")}
                      style={{ minHeight: "100px" }}
                      required
                    />
                    <Row>
                      {form.documents.map((document, index) => (
                        <div key={index} className="d-flex flex-column align-items-center justify-content-end col-sm-3 mt-3">
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100px" }}>
                            {imageLoading !== index ? (
                              <>
                                <img
                                  src={form.documents[index]?.file}
                                  // className="img-fluid"
                                  alt="user"
                                  loading="lazy"
                                  style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                />
                                <input
                                  type="text"
                                  value={form.documents[index]?.name}
                                  style={{
                                    position: "relative",
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    height: 0,
                                    width: 0,
                                    bottom: "50px"
                                  }}
                                  required
                                />
                              </>
                            ) : <SpinningBlueCircleLoader />}
                          </div>
                          <div className="d-flex flex-row gap-2 mt-4">
                            <div className="d-flex align-items-center">
                              <label htmlFor="picture" className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                <i className="material-symbols-outlined" onClick={() => fileInputRefs.current[index].click()}>attach_file</i>
                              </label>
                              <input
                                ref={(el) => (fileInputRefs.current[index] = el)}
                                name={`picture[${index}]`}
                                type="file"
                                accept="image/*"
                                style={{
                                  opacity: 0,
                                  pointerEvents: 'none',
                                  height: 0,
                                  width: 0,
                                }}
                                onChange={(e) => handleAttachPicture(e, index)}
                              />
                            </div>
                            {form.documents.length > 1 &&
                              <button
                                type="button"
                                className="border-0 bg-transparent d-flex align-items-center text-danger"
                                onClick={() => setForm({ ...form, documents: form.documents.filter((_, i) => i !== index) })}
                              >
                                <i className="material-symbols-outlined">delete</i>
                              </button>
                            }
                          </div>
                        </div>
                      ))}
                      <div className="d-flex col-sm-3 mt-3 align-items-center justify-content-center" style={{ height: "100px" }}>
                        <i className="material-symbols-outlined filled text-primary w-auto cursor-pointer" style={{ fontSize: "27px" }} onClick={() => setForm({ ...form, documents: [...form.documents, { name: "", file: defaultImage }] })}>add_box</i>
                      </div>
                    </Row>
                    <button type="submit" className="btn btn-primary w-100 mt-5">
                      {!loading ? 'Save' : <SpinningWhiteCircleLoader />}
                    </button>
                  </>
                }
              </div>
              <div className="col-sm-5">
                <label className="control-label align-self-center mt-5" htmlFor="status">Status</label>
                {isView
                  ? <input type="text" value={details?.status ?? ""} className="form-control" id="status" name="status" placeholder="-" readOnly />
                  : <CustomSelect
                    value={status}
                    onChange={(e) => {
                      setStatus(e);
                      handleChangeStatus(e);
                    }}
                    options={details?.status === "IN-PROGRESS"
                      ? [{ value: "IN-PROGRESS" }, { value: "VIOLATED" }, { value: "NOT-VIOLATED" }]
                      : [{ value: "VIOLATED" }, { value: "NOT-VIOLATED" }]
                    }
                    optLabelKey="value"
                    optValueKey="value"
                    placeholder="-"
                    readOnly={isView}
                  />
                }
                <label className="control-label align-self-center mt-2" htmlFor="tracking">Tracking</label>
                <input type="text" value={details?.referenceNumber ?? ""} className="form-control" id="tracking" name="tracking" placeholder="-" readOnly />
                <label className="control-label align-self-center mt-2" htmlFor="submitted">Date Submitted</label>
                <input type="text" value={details?.createdAt ?? ""} className="form-control" id="submitted" name="submitted" placeholder="-" readOnly />
                {/* <label className="control-label align-self-center mt-2" htmlFor="start">Start Date</label>
                <input type="text" value={details?.startDate ?? ""} className="form-control" id="start" name="start" placeholder="-" readOnly />
                <label className="control-label align-self-center mt-2" htmlFor="end">End Date</label>
                <input type="text" value={details?.endDate ?? ""} className="form-control" id="end" name="end" placeholder="-" readOnly /> */}
                <label className="control-label align-self-center mt-2" htmlFor="status">Assigned</label>
                <input type="text" value={details?.assigned ?? ""} className="form-control" id="status" name="status" placeholder="-" readOnly />
                <label className="control-label align-self-center mt-2" htmlFor="status">Documents</label>
                {commentsLoading
                  ? <SpinningBlueCircleLoader />
                  : (documents.length > 0)
                    ? <div className="form-control" style={{ width: "100%", display: "flex", flexFlow: "wrap", }}>
                      {documents?.map((doc, idx) => (
                        <div
                          key={idx}
                          className="rounded-3 bg-danger"
                          style={{ width: "fit-content", color: "white", padding: "5px 8px", margin: "0 5px 5px 0px", overflow: "auto" }}
                          onClick={() => handleSelectView(doc)}
                        >
                          {doc.name}
                        </div>
                      ))}
                    </div>
                    : <div className="d-flex justify-content-center">No record found</div>
                }
                <h6 className="mt-3" style={{ fontWeight: 400 }}>History logs</h6>
                {commentsLoading
                  ? <SpinningBlueCircleLoader />
                  : (comments.length > 0)
                    ? comments?.map((comment, idx) => (
                      <div key={idx} className="mt-2 ms-3 me-3">{comment.comment}</div>
                    ))
                    : <div className="d-flex justify-content-center">No record found</div>
                }
              </div>
            </Row>

          </Form >
        </Card.Body>
      </Card>

      <ConfirmModal
        open={openModal.save}
        onHide={() => setOpenModal({ ...openModal, save: false })}
        titleIcon={<i className="material-symbols-outlined text-success" style={{ fontSize: "130px" }}>check_circle</i>}
        titleText={<h5 className="text-success">Save</h5>}
        confirmText="Successfully saved."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-success w-100" onClick={() => setOpenModal({ ...openModal, save: false })}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.delete}
        onHide={() => setOpenModal({ ...openModal, delete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Successfully deleted."
        isOneAction={true}
        confirmButton={
          <button className="btn btn-danger w-100" onClick={() => {
            setOpenModal({ ...openModal, delete: false });
            setShowAddNew(false);
            setDetails(null);
          }}>
            Okay
          </button>
        }
      />

      <ConfirmModal
        open={openModal.confirmDelete}
        onHide={() => setOpenModal({ ...openModal, confirmDelete: false })}
        titleIcon={<i className="material-symbols-outlined text-danger" style={{ fontSize: "130px" }}>cancel</i>}
        titleText={<h5 className="text-danger">Delete</h5>}
        confirmText="Are you sure you want to delete?"
        isTwoActions={true}
        confirmButton={
          <button className="btn btn-danger w-50" onClick={handleDelete}>
            Yes
          </button>
        }
        cancelButton={
          <button className="btn btn-primary w-50" onClick={() => setOpenModal({ ...openModal, confirmDelete: false })}>
            No
          </button>
        }
      />

      <FileViewerComp toggleView={toggleView} setToggleView={setToggleView} fileView={fileView} />
    </>
  );
}

export default ViolationStatus;